<template>
  <div>
    <levantamiento-de-datos v-if="punto" :is-new="true" :punto-iluminacion="punto" :is-read-only="false"/>
  </div>
</template>

<script>
import LevantamientoDeDatos from '@/components/PuntosIluminacion/LevantamientoDeDatos.vue'
import { getPoste } from '@/utils/postes'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  name: 'ViewLevantamientoDeDatos',
  components: { LevantamientoDeDatos },
  data() {
    return {
      id: router.currentRoute.params.id,
      punto: null,
    }
  },
  async beforeMount() {
    this.punto = await getPoste(this.id)
  },
}
</script>
